.tnt-slider {
    position: relative;
    z-index: 99;
}
.tnt-slider .tnt-slider-outer {
    overflow: hidden;
}
.tnt-slider .tnt-slider-inner {
    white-space: nowrap;   
    position: relative;
    overflow: hidden;
    transition: all 0.25s ease 0s;
    user-select: none;
}
.tnt-slider .tnt-slider-inner.tnt-slider-resizing {
    transition: all 0s ease 0s;
}
.tnt-slider .tnt-slider-item {
    position: relative;
    float: left;
    min-height: 1px;
    white-space: normal;
    touch-action: pan-y;
}

/* ad slide swipe control */
.tnt-slider-item .item-container .tnt-slider-ad-nav {
    width: 115px;
    border-radius: 0 100px 100px 0;
    position: absolute;
    top: 34px;
    right: auto;
    left: 0;
    bottom: 34px;
    background: transparent;
}

.tnt-slider-item .item-container .tnt-slider-ad-nav.right {
    right: 0;
    left: auto;
    border-radius: 100px 0 0 100px;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-item .item-container {
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-areas:
        "photo photo"
        "position caption";
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-item .item-container.ad-container {
    display: block;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-item .item-container .photo-container {
    grid-area: photo;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-item .item-container .paging-position {
    grid-area: position;
    padding: 0px;
    width: 34px;
    height: 34px;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-item .item-container .caption-container-paging {
    grid-area: caption;
    padding: 0px;
}

.tnt-slider-height {
  transition: height 500ms ease-in-out;
}

/* Navigation Control Styles
***************************/
.tnt-slider .tnt-slider-controls {
    margin: 0;
    position: absolute;
    right: 0px;
    z-index: 200;
}
.slideshow-carousel-container .tnt-slider .tnt-slider-controls {
    top: 0;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls {
    position: inherit;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: inherit;
    display: grid;
    grid-template-columns: 1fr min-content min-content;
    column-gap: 5px;
    align-items: end;

}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls.tnt-slider-controls-top {
    margin-bottom: 5px;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls.tnt-slider-controls-bottom {
    margin-top: 5px;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls .tnt-slider-previous,
.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls .tnt-slider-next {
    opacity: 1;
    position: inherit;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: inherit;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls .tnt-slider-previous,
.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls .tnt-slider-next {
    height: 100%;
    display: grid;
    border: 1px solid #ddd;
    color: #222;
    width: 44px;
    height: 44px;
}

@media screen and (min-width: 768px) {
    .tnt-slider[data-slider-type="pagination"] .tnt-slider-controls .tnt-slider-previous,
    .tnt-slider[data-slider-type="pagination"] .tnt-slider-controls .tnt-slider-next {
        width: 34px;
        height: 34px;
    }
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls .tnt-slider-btn .tnt-svg {
    margin: auto;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-controls span.tnt-slider-control-txt {
    display: none;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-counter {
   font-weight: bold;
}

.tnt-slider[data-slider-type="pagination"][data-show-count="false"] .tnt-slider-counter {
    visibility: hidden;
}

.tnt-slider[data-slider-type="pagination"] .tnt-slider-counter p {
    margin-bottom: 0px;
}

.photo-carousel .tnt-slider-controls .tnt-slider-btn {
    color: rgba(0,0,0,.4);
    cursor: pointer;
    z-index: 2;
    position: absolute;
    background-color: rgba(255,255,255,.9);
    padding: 5px 10px;
    font-size: 18px;
    user-select: none;
    opacity: .6;
    top: 0px;
}

.photo-carousel:hover .tnt-slider-controls .tnt-slider-btn {
    opacity: 1;
}

.photo-carousel .tnt-slider-controls .tnt-slider-btn {
    color: rgba(0,0,0,.8);
    background-color: #fff;
}

.photo-carousel .tnt-slider-controls .tnt-slider-previous {
    left: auto;
    right: 40px;
    top: 5px;
}
.photo-carousel .tnt-slider-controls .tnt-slider-next {
    right: 5px;
    top: 5px;
}

/* Modal */
.modal .tnt-slider .tnt-slider-inner,
.modal .tnt-slider .tnt-slider-item,
.modal .tnt-slider .tnt-slider-outer {
    height: 100% !important;
    margin: 0 auto;
}

@media only screen and (min-width: 960px) {    
    .modal .tnt-slider .tnt-slider-inner,
    .modal .tnt-slider .tnt-slider-item,
    .modal .tnt-slider .tnt-slider-outer {
        width: 900px;
    }
    
}

.modal .tnt-slider .tnt-slider-item.active{
    margin-right: 0;
    height: 100% !important;
}

.modal .tnt-slider {
    position: relative;
    z-index: 99;
    width: 100%;
    margin: 0 auto;
    height: 100% !important;
}

.modal.fullscreen .tnt-slider .tnt-slider-previous,
.modal.fullscreen .tnt-slider .tnt-slider-next {
    background-color: rgba(0,0,0,.3);
    color: #fff;
    font-size: 28px;
    padding: 10px;
    opacity: 1;
    bottom: 15px;
    top: auto;
    left: auto;
}

.modal.fullscreen .tnt-slider .tnt-slider-previous{
    right: 58px;
}

.modal.fullscreen .tnt-slider .tnt-slider-next{
    right: 15px;
}

.modal .tnt-slider .tnt-slider-controls {
    margin: 0;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    z-index: 200;
}

.modal .tnt-slider .photo-container div[itemprop="image"]{
    height: 100%;
}

.modal .tnt-slider .gallery-ad{
    max-height: 100%;
    max-width: 100%;
    height: initial;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#asset-photo-carousel .tnt-slider .item .photo-container img {
    z-index: 9;
    position: relative;
}

#asset-photo-carousel .tnt-slider .tnt-slider-controls  {
    top: 0;
}

/* Photo count */
#asset-photo-carousel .tnslider-photo-count {
    right: 0;
    top: -35px;
    z-index: 1;
    position: absolute;
    font-weight: bold;
    padding: 15px 0px 0px 0px;
}